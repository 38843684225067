import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import axios from 'axios';
import { showWarningLayer} from '../../utils/utils';
import './monthly-service-usage.styles.scss';
import 'react-tabs/style/react-tabs.css';


class MonthlyServiceUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      services: [],
      filtersMonth: [
        { "value":"01","label":"Ocak"},
        { "value":"02","label":"Şubat"},
        { "value":"03","label":"Mart"},
        { "value":"04","label":"Nisan"},
        { "value":"05","label":"Mayıs"},
        { "value":"06","label":"Haziran"},
        { "value":"07","label":"Temmuz"},
        { "value":"08","label":"Ağustos"},
        { "value":"09","label":"Eylül"},
        { "value":"10","label":"Ekim"},
        { "value":"11","label":"Kasım"},
        { "value":"12","label":"Aralık"}
      ],
      filtersYear: [
        { "value":"20","label":"2020"},
        { "value":"21","label":"2021"},
        { "value":"22","label":"2022"},
        { "value":"23","label":"2023"},
        { "value":"24","label":"2024"},
        { "value":"25","label":"2025"},
        { "value":"26","label":"2026"},
        { "value":"27","label":"2027"},
        { "value":"28","label":"2028"},
        { "value":"29","label":"2029"},
        { "value":"30","label":"2030"}
      ],
      disableYear:false,
      selectedOptionMonth: null,
      selectedOptionYear: null,
      currentOptionMonthValue: "",
      currentOptionYearValue: "",
    }

  }

  componentDidMount() {
    this.getMonthlyServiceUsage();
  }


  getMonthlyServiceUsage = () => {
    this.setState({
      ...this.state,
      loading: true
    });
    let month = this.state.currentOptionMonthValue;
    let year = this.state.currentOptionYearValue;
    if (month === "" || year === ""){
      this.setState({ ...this.state, loading: false });
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ay ve yıl seçiniz.'});
      showWarningLayer();
      return;
    }

    

    axios.post(`https://azr04.infotraq.com/servisradarsu/get-monthly-service-usage-invoiced`, { "sid": this.props.user.SessionID, "month": month,"year":year }, { crossDomain: true })
      .then(res => {
        if (res.data.type === "error") {
          if (res.data.message === "DP_Session") {
            this.props.logOut();
            this.props.setDefault();
            const bodyElement = document.querySelector('body');
            bodyElement.classList.remove('noBodyImage');
            
          }
        } else {
          let services;
          try {
            services = res.data.data;
            if (services === null || services === undefined) {
              services = [];
            }
          } catch (ex) {
            services = [];
          }

          this.setState({ ...this.state, services: services, loading: false });
          
        }
      })
  }

  handleFilterMonthChange = (selectedOptionMonth) => {
    this.setState(
      {
        ...this.state,
        selectedOptionMonth,
        currentOptionMonthValue:selectedOptionMonth.value
      }
    );    
  }

  handleFilterYearChange = (selectedOptionYear) => {
    this.setState(
      {
        ...this.state,
        selectedOptionYear,
        currentOptionYearValue:selectedOptionYear.value
      }
    );    
  }  


  goBack = () => {
    this.props.history.push('/raporlar', this.props.location.state);
  }

  render() {
    //console.log(this.state.activeServices);
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 300,
      noDataText: 'Gösterilecek kayıt yok'
    };

    //let tempDate = this.props.serviceRow.SERVICEDATE.split(" ");
    //let tempName = this.props.serviceRow.SERVICENAME.split(" ");
    //let currentDate = tempDate[0].replace(/[\/]/g, '-'); //eslint-disable-line
    //const excelFileName = currentDate + " " + tempName[0] + "-" + tempName[2];
    let currentDate = new Date();
    currentDate = currentDate.toISOString().split("T")[0];

    const excelFileName = currentDate;


    return (
      <div>
        <div className="card card-body bg-light content-toolbar">
          <div className="d-flex">
            <div className="d-flex p-2">
            
            <label className="filterLabelServisTarihSaat">Rapor Ay / Yıl : </label>
            <Select
              className="filterSelectYolcuRaporu firstSelect"
              options={this.state.filtersMonth} 
              // value={this.state.selectedOptionTarih}
              onChange={this.handleFilterMonthChange}
              placeholder="Ay seçiniz.."
            />
            <Select
              className="filterSelectYolcuRaporu"
              options={this.state.filtersYear} 
              value={this.state.selectedOptionYear}
              onChange={this.handleFilterYearChange}
              placeholder="Yıl seçiniz.."
              isDisabled={this.state.disableYear}
            />          
            </div>
            <div className="d-flex">
              <button type="button" className="btn btn-general btn-light margin-left-5 btnalonesearch" onClick={() => this.getMonthlyServiceUsage()} ><i className="fas fa-search"></i> Filtrele</button>                
            </div>            
            <div className="ml-auto p-2">
              <div className="d-flex">
                <ReactHTMLTableToExcel
                  className={`btn btn-info btn-excel`}
                  table="emp-reserved"
                  filename={`${excelFileName} Aylık Faturalı Servis Kullanımı`}
                  sheet="Aylık Faturalı Servis Kullanımı"
                  buttonText="Excel'e aktar"
                />


                <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>
              </div>
            </div>
          </div>
        </div>

        <table id="emp-reserved" className="excel-table">
        <tbody>
          <tr>
            <td><b>Kullanıcı Adı ve Birim</b></td>            
            <td><b>Kullanıcı Adı</b></td>
            <td><b>E-Posta</b></td>
            <td><b>Telefon</b></td>
            <td><b>Birim</b></td>
            <td><b>Görevi</b></td>
            <td><b>Çalışma Şekli</b></td>
            <td><b>Sicil No</b></td>
            <td><b>Aktif mi ?</b></td>
            <td><b>Servis Tarihi</b></td>
            <td><b>Servis Adedi</b></td>
            <td><b>Rota</b></td>
            <td><b>Rota Yönü</b></td>
            <td><b>Birim Proje</b></td>
            <td><b>Maks. Yolcu</b></td>
            <td><b>Kapasite</b></td>
          </tr>
          {
            this.state.services.map((s, index) => {
              return <tr key={index}>
                <td >{s.ADSOYADBIRIM}</td>                
                <td >{s.KULLANICIADI}</td>
                <td >{s.EMAIL}</td>
                <td >{s.PHONENUMBER}</td>
                <td >{s.BIRIM}</td>
                <td >{s.GOREVI}</td>
                <td >{s.CALISMASEKLI}</td>
                <td >{s.IDNO}</td>
                <td >{s.ISACTIVE}</td>
                <td >{s.SERVISTARIHI}</td>
                <td >{s.SERVISADEDI}</td>
                <td >{s.ROUTENAME}</td>
                <td >{s.ROTAYONU}</td>
                <td >{s.BIRIMYENI}</td>
                <td >{s.MAXYOLCU}</td>
                <td >{s.KAPASITE}</td>
              </tr>
            })
          }

        </tbody>
      </table>

      {
        this.state.loading ? <LoadingIndicator /> :
          this.state.services.length > 0 ? (
            <BootstrapTable data={this.state.services} containerClass='services-list' striped hover options={options} pagination>
              <TableHeaderColumn dataField='ID' isKey={true} hidden>ID</TableHeaderColumn>
              <TableHeaderColumn dataField='ADSOYADBIRIM' width='350px' dataSort={true}>Kullanıcı Adı ve Birim</TableHeaderColumn>              
              <TableHeaderColumn dataField='KULLANICIADI' width='350px'  dataSort={true}>Ad / Soyad</TableHeaderColumn>
              <TableHeaderColumn dataField='EMAIL' width='350px' dataSort={true}>E-Posta</TableHeaderColumn>
              <TableHeaderColumn dataField='PHONENUMBER' width='200px' dataSort={true}>Telefon Numarası</TableHeaderColumn>
              <TableHeaderColumn dataField='BIRIM' width='200px' dataSort={true}>Birim</TableHeaderColumn>
              <TableHeaderColumn dataField='GOREVI' width='200px' dataSort={true}>Görevi</TableHeaderColumn>
              <TableHeaderColumn dataField='CALISMASEKLI' width='200px' dataSort={true}>Çalışma Şekli</TableHeaderColumn>
              <TableHeaderColumn dataField='IDNO' dataSort={true}>Sicil No</TableHeaderColumn>
              <TableHeaderColumn dataField='ISACTIVE' dataSort={true}>Aktif mi ?</TableHeaderColumn>
              <TableHeaderColumn dataField='SERVISTARIHI' width='200px' dataSort={true}>Servis Tarihi</TableHeaderColumn>
              <TableHeaderColumn dataField='SERVISADEDI' width='200px' dataSort={true}>Servis Adedi</TableHeaderColumn>
              <TableHeaderColumn dataField='ROUTENAME' width='500px' dataSort={true}>Rota</TableHeaderColumn>
              <TableHeaderColumn dataField='ROTAYONU' width='200px' dataSort={true}>Rota Yönü</TableHeaderColumn>
              <TableHeaderColumn dataField='BIRIMYENI' width='200px' dataSort={true}>Birim Proje</TableHeaderColumn>
              <TableHeaderColumn dataField='MAXYOLCU' width='200px' dataSort={true}>Maks Yolcu</TableHeaderColumn>
              <TableHeaderColumn dataField='KAPASITE' width='200px' dataSort={true}>Kapasite</TableHeaderColumn>
            </BootstrapTable>
          ) :
            <p>Gösterilecek kayıt yok.</p>
      }        

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    services: state.services,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload }),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading }),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MonthlyServiceUsage));