import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginPage from './pages/login/loginpage.component';
import Servisler from './pages/servisler/servisler.component';
import ServisBilgisi from './pages/servis-bilgisi/servis-bilgisi.component';
import DigerYolcular from './pages/diger-yolcular/diger-yolcular.component';
import YolcuIslemleri from './pages/yolcu-islemleri/yolcu-islemleri.component';
import ServisIslemleri from './pages/servis-islemleri/servis-islemleri.component';
import YolcuListesi from './pages/raporlar/yolcu-listesi.component'; 
import ServisYolcu from './pages/raporlar/servis-yolcu.component'; 
import AylikFaturaliServis from './pages/raporlar/aylik-faturali-servis.component'; 
import RotayaGoreAylikServisAdetleri from './pages/raporlar/rotaya-gore-aylik-servis-adetleri.component'; 
import RotayaGoreAylikServisAdetleriDetayli from './pages/raporlar/rotaya-gore-aylik-servis-adetleri-detayli.component';

import SurucuYolcuListesi from './pages/servisler/surucu-yolcu-listesi.component'; 
import KullaniciServisleri from './pages/yolcu-islemleri/kullanici-servisleri.component'; 
import RotaTanimlari from './pages/rota-tanimlari/rota-tanimlari.component'; 
import AracTanimlari from './pages/tanimlamalar/arac-tanimlari.component'; 
import SurucuTanimlari from './pages/tanimlamalar/surucu-tanimlari.component'; 
import KullaniciTanimlari from './pages/tanimlamalar/kullanici-tanimlari.component'; 
import ZamanlamaTanimlari from './pages/tanimlamalar/zamanlama-tanimlari.component'; 
import GuzergahTanimlari from './pages/tanimlamalar/guzergah-tanimlari.component'; 

import ServisYolcuAktarma from './pages/servis-islemleri/servis-yolcu-aktarma.component';
import ServisLimit from './pages/servis-islemleri/servis-limit.component';
import GuzergahAracSurucu from './pages/servis-islemleri/guzergah-arac-surucu.component';

import Hosgeldiniz from './pages/hosgeldiniz/hosgeldiniz.component'; 
import ServisDegistir from './pages/servis-degistir/servis-degistir.page'; 

import Raporlar from './pages/raporlar/raporlar.component';
import YeniYolcu from './pages/yolcu-islemleri/yeni-yolcu.component';
import DuzeltYolcu from './pages/yolcu-islemleri/duzelt-yolcu.component';
import ResetPassword from './pages/login/resetpassword.component';
import WarningLayer from './components/warning-layer/warning-layer.component';
import LoadingLayer from './components/loading-layer/loading-layer.component';
import { LoginActionTypes } from './redux/login/login.types';
import { hideWarningLayer } from './utils/utils';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/library/kendo/css/kendo.common-office365.min.css';
import './assets/library/kendo/css/kendo.office365.min.css';
import './assets/styles/all-fonts.min.css';
import './assets/styles/edms-core.scss';

class App extends React.Component {

  componentDidMount(){
    this.props.setLoading(false)
    setTimeout(()=>{
      this.props.hideLoading();
    },1000);
  }
  render(){
    return (
      <div className="mainID">
        <Switch>
          <Route exact path='/'>
            {
              //&& this.props.currentPage === 'servisler' 
               this.props.isLoggedIn && this.props.loginStart === false ? 
              <Redirect to="/servis-bilgisi" /> : <LoginPage  /> 
            }
          </Route>
          <Route exact path='/resetpassword' component={ResetPassword} />
          <Route exact path='/servis-bilgisi' component={ServisBilgisi} />
          <Route exact path='/diger-yolcular' component={DigerYolcular} />

          <Route exact path='/servis-degistir' component={ServisDegistir} />

          <Route exact path='/servisler' component={Servisler} />
          <Route exact path='/raporlar' component={Raporlar} />
          <Route exact path='/servis-islemleri' component={ServisIslemleri} />
          <Route exact path='/yolcu-islemleri' component={YolcuIslemleri} />
          <Route exact path='/rota-tanimlari' component={RotaTanimlari} />
          <Route exact path='/raporlar-yolcu-listesi' component={YolcuListesi} />
          <Route exact path='/raporlar-servis-yolcu-listesi' component={ServisYolcu} />
          <Route exact path='/raporlar-aylik-faturali-servis-kullanimi' component={AylikFaturaliServis} />
          <Route exact path='/raporlar-rotaya-gore-aylik-servis-adetleri' component={RotayaGoreAylikServisAdetleri} />
          <Route exact path='/raporlar-rotaya-gore-aylik-servis-adetleri-detayli' component={RotayaGoreAylikServisAdetleriDetayli} />
          <Route exact path='/yolcu-islemleri-kullanici-servisleri' component={KullaniciServisleri} />
          <Route exact path='/yolcu-islemleri-yolcu-ekle' component={YeniYolcu} />
          <Route exact path='/yolcu-islemleri-yolcu-duzelt' component={DuzeltYolcu} />
          <Route exact path='/surucu-yolcu-listesi' component={SurucuYolcuListesi} />
          <Route exact path='/arac-tanimlari' component={AracTanimlari} />
          <Route exact path='/surucu-tanimlari' component={SurucuTanimlari} />
          <Route exact path='/kullanici-tanimlari' component={KullaniciTanimlari} />
          <Route exact path='/zamanlama-tanimlari' component={ZamanlamaTanimlari} />
          <Route exact path='/guzergah-tanimlari' component={GuzergahTanimlari} />
          <Route exact path='/servis-yolculari-aktarma' component={ServisYolcuAktarma} />
          <Route exact path='/servis-limit-degistirme' component={ServisLimit} />
          <Route exact path='/guzergah-arac-ve-surucu-degistirme' component={GuzergahAracSurucu} />
          <Route exact path='/hosgeldiniz' component={Hosgeldiniz} />

          <Route>
            { this.props.isLoggedIn ? 
                this.props.user.UserRoles.includes("SUADMIN") ? <Redirect to="/hosgeldiniz" /> : this.props.user.UserRoles.includes("SUMANAGER") ? <Redirect to="/raporlar" /> : <Redirect to="/servis-bilgisi" /> 
              : <LoginPage  />
            }
          </Route>

        </Switch>
        <LoadingLayer showLoading={this.props.showLoading} />
        <WarningLayer hideWarning={hideWarningLayer} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
        <div id="jqueryPopupContainer"></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    user: state.login.User,
    showLoading: state.login.showLoading,
    isLoading: state.login.isLoading,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage,
    pageload: state.project.pageload
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
