import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import Select from 'react-select';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';
import PopupModal from '../../components/popup-modal/popup-modal.component';
import { showWarningLayer, showWarningLayerPopup, hideWarningLayerPopup, generateID, compareDatesSU } from '../../utils/utils';

import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import axios from 'axios';

import './service-limits.styles.scss';

import '../../assets/styles/react-bootstrap-table-all.min.css';

class ServiceLimits extends React.Component {
  constructor(props){
    super(props);
    //console.log(this.props.location.state);
    this.props.setServices([]);
    this.excelData = [];
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
    this.searchRef = React.createRef()
    this.state = {
      loading: false,
      filters: this.props.location.state === undefined ? [] : this.props.location.state.filters,
      selectedOption: this.props.location.state === null || this.props.location.state === undefined  ? null : this.props.location.state.selectedOption,
      excelFileName: "",
      modalShowPopup:false,
      isDisableFilter:true,
      defaultSearch:'',
      disableSaat:true,
      selectedOptionSaat: this.props.location.state === null || this.props.location.state === undefined  ? null : this.props.location.state.selectedOptionSaat,
      currentOptionTarihValue: "",
      currentOptionSaatValue: "",
      currentService: null,
      drivers:[],
      buses:[],
      selectedOptionBus: null,
      selectedOptionDriver: null
    }

  }

  componentDidMount(){
    if(this.props.location.state === undefined){
      this.getServiceSummaryDates();
    }
    if (this.props.location.state !== undefined){
      this.getServiceSummary(this.props.location.state.selectedOption);    
    }

  }
 
  getServiceSummaryDates = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services-summary-dates`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let filters;

        try{
          filters = res.data.data;
          if (filters === null || filters === undefined){
            filters = [];
          }          
        }catch(ex){
          filters = [];
        }
        
        this.setState({...this.state, filters: filters,selectedOptionSaat:null, loading:false });        
        
      }     
    })
  }

  getServiceSummary = (selectedOption) => {
    this.setState({
      ...this.state,
      loading:true,
      isDisableFilter:true,
      saatGoster:false
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services-summary`,{ "sid": this.props.user.SessionID,"sdate":selectedOption.value},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        this.props.setServices(services);
        let tempDate = selectedOption.label.split(" ");
        let currentDate = tempDate[0].replace(/[\/]/g,'-'); //eslint-disable-line
        const excelFileName = currentDate;
        this.getSaatFilters(selectedOption.value);
        this.setState({...this.state, selectedOption,excelFileName:excelFileName,selectedOptionSaat:null, isDisableFilter:false });
      }     
    })
  }

  getSaatFilters = (tarihValue) => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-activeservice-times`,{ "sid": this.props.user.SessionID, "sdate": tarihValue},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let filtersSaat;
        try{
          filtersSaat = res.data.data;
          if (filtersSaat === null || filtersSaat === undefined){
            filtersSaat = [];
          }          
        }catch(ex){
          filtersSaat = [];
        }
        filtersSaat.unshift({"value":'',"label":"Hepsi"});
        this.setState(
          {
            ...this.state,
            filtersSaat: filtersSaat,
            disableSaat:false,
            loading:false
          }
        );
        //this.props.setPassengersList([]);
        if (this.props.location.state !== undefined){
          this.refs.servicenameCol.applyFilter(this.state.selectedOptionSaat.value);
        }

      }     
    })
  }  



  updateLimits = async () => {
    this.props.setLoadingPopup(true);
    let serviceid = this.state.currentService.SERVICEID;
    let maxpassengers = this.maxpassengers.value;  
    let maxcapacity = this.maxcapacity.value;  
    
    

    if (serviceid === "" || serviceid === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen servis seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }


    if (maxpassengers < 1  || maxpassengers === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen servis yolcu limitini kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (maxcapacity < 1  || maxcapacity === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen servis yolcu kapasitesini kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }


    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-service-limits`,
      {
        "sid": this.props.user.SessionID,
        "serviceid":serviceid,
        "maxpassengers":maxpassengers,
        "maxcapacity":maxcapacity
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Busid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen servis seçiniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Serviceid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen servis seçiniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Maxpassengers"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen servis yolcu limiti giriniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Maxcapacity"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen servis yolcu kapasitesi giriniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Servis limitleri güncellendi.'});
        showWarningLayer();
        this.getServiceSummary(this.state.selectedOption); 
        this.setState({...this.state,modalShowPopup:false});
       
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }    






  handleChangePopup = (row) => {
    if (row !== null){
      //console.log(row);
      this.setState({...this.state, currentService: row, modalShowPopup:true});
    }
  }  


  handleChangeBuses = selectedOptionBus => {
    this.setState(
      { selectedOptionBus });
  };   

  handleChangeDriver = selectedOptionDriver => {
    this.setState(
      { selectedOptionDriver });
  };   




  buttonDetail = (cell, row) => {
    // let tempHour = row.SERVICENAME.split("|")[1].trim();
    // let buttonShow = compareDatesSU(row.SERVICEDATE.split(" ")[0],tempHour);
    return (
      // <button type="button" className="btn btn-warning btn-rota-detay" disabled={buttonShow === true ? false : true} onClick={() => this.handleChangePopup(row)}>Değiştir</button>
      <button type="button" className="btn btn-warning btn-rota-detay" onClick={() => this.handleChangePopup(row)}>Değiştir</button>
    )
  }

  routeType = (cell, row) => {
    return cell === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"
  }

  // handleFilterSearch = (value) => {
  //   try{ this.refs.servicenameCol.applyFilter(value);} catch(ex){}
  // }

  handleFilterSearch = (value) => {
    //this.refs.servicenameCol.applyFilter(value);

    if (value === ""){
      try{
        this.refs.tableService.handleSearch(value);
        this.setState({defaultSearch: value});
      }catch(e){
        return false;
      }
      
    }else{
      if (value.length >= 3){
        try{
          this.refs.tableService.handleSearch(value);
          this.setState({defaultSearch: value});
        }catch(ex){
          return false;
        }
      }else{
        return false;
      }
    }
    
  }

  handleSaatChange = (selectedOption) => {
    try{
       this.refs.servicenameCol.applyFilter(selectedOption.value);
       this.setState({...this.state, selectedOptionSaat: selectedOption });      
      
      } catch(ex){}
  }  


  handleFilterChange = (selectedOption ) => {
    this.getServiceSummary(selectedOption);
//    this.refs.servicedateCol.applyFilter(selectedOption.value);
  }

  afterColumnFilter = (filterConds, result) => {
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
  }

  afterSearch = (searchText, result) => {
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
  }  

  render(){
    const editGuid = generateID();
    const options = {
      // sizePerPageList: [],
      // hidePageListOnlyOnePage: true,
      // sizePerPage: 100,
      //afterColumnFilter: this.afterColumnFilter,
      afterSearch: this.afterSearch,
      defaultSearch: this.state.defaultSearch,
      noDataText: 'Gösterilecek kayıt yok'
      
    };
    const footerData = [
      [
        {
          label: '',
          columnIndex: 2,
          align: 'center',
          formatter: (tableData) => {
            let total = tableData.length;
            return (
              <strong>Toplam : { total } adet</strong>
            );
          }
          
        },
        {
          label: '',
          columnIndex: 7,
          align: 'center',
          formatter: (tableData) => {
            let labelReservation = 0;
            let labelMaxPassengers = 0;
            let labelTotalPassengers = 0;
            for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
              labelReservation += parseInt(tableData[i].RESERVATIONCOUNT) || 0;
              labelMaxPassengers += parseInt(tableData[i].MAXPASSENGERS) || 0;
              labelTotalPassengers += parseInt(tableData[i].TOTALPASSENGERS) || 0;
            }
            return (
              <strong>Toplam : { labelReservation } / { labelMaxPassengers } / { labelTotalPassengers }</strong>
            );
          }
        }
      ]
    ];    
     
    
    return(
      <div>
      <div className="card card-body bg-light content-toolbar">
      <div className="d-flex">
          <div className="p-2 d-flex">
          <label className="filterLabelServisTarihi">Servis Tarih / Saat : </label>
          <Select
            className="filterSelect"
            options={this.state.filters} 
            value={this.state.selectedOption}
            onChange={this.handleFilterChange}
            placeholder="Servis tarihi seçin.."
          />
          <Select
          className="filterSelectYolcuRaporu"
          options={this.state.filtersSaat} 
          value={this.state.selectedOptionSaat}
          onChange={this.handleSaatChange}
          placeholder="Saat seçiniz.."
          isDisabled={this.state.disableSaat}
        />          
          </div>
          <div className="ml-auto p-2">
              <div className="d-flex">
                <label className="filterLabelYolcuRapor">Filtrele : </label> <input id="editFilter" ref={this.searchRef} type="text" className="form-control edit" placeholder="Arama kriteri" onChange={(event) => this.handleFilterSearch(event.target.value)}  />
              </div>
          </div>
      </div>   
      </div>       
      {
        this.state.loading ? <LoadingIndicator /> :
        this.props.services.length > 0 ? (
          <BootstrapTable ref='tableService' data={this.props.services} containerClass='services-list' striped hover options={options} footerData={ footerData } footer search>
            <TableHeaderColumn dataField='SERVICEID' isKey={true} hidden>ID</TableHeaderColumn>
            <TableHeaderColumn ref='servicedateCol' hidden dataField='SERVICEDATE' filter={ { type: 'TextFilter', delay: 1000 } }>Servis Tarihi</TableHeaderColumn>
            <TableHeaderColumn ref='servicenameCol' width='350px' className='servicenameCol'  filter={ { type: 'TextFilter', delay: 1000 } } dataField='SERVICENAME' dataSort={ true }>Servis Adı</TableHeaderColumn>
            <TableHeaderColumn dataField='ROUTETYPE' width='200px' dataAlign='center' headerAlign='center' dataFormat={ this.routeType } dataSort={ true }>Servis Yönü</TableHeaderColumn>
            <TableHeaderColumn dataField='BUSPLATE' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Plaka </TableHeaderColumn>
            <TableHeaderColumn dataField='BUSDRIVER' width='250px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Adı </TableHeaderColumn>
            <TableHeaderColumn dataField='DRIVERPHONE' width='200px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Cep Telefonu </TableHeaderColumn>
            <TableHeaderColumn dataField='CAPACITY' dataAlign='center' headerAlign='center' width='280px' dataSort={ true }>Rezervasyon / Kapasite / Toplam </TableHeaderColumn>
            <TableHeaderColumn dataField="button" width='170px' tdStyle={ { padding: '5px' } } dataFormat={this.buttonDetail} dataSort={ true }></TableHeaderColumn>
          </BootstrapTable>   
        ) : 
        <p>Gösterilecek kayıt yok.</p>
      }
              
      <PopupModal
          show={this.state.modalShowPopup}
          onHide={() => this.setState({...this.state,modalShowPopup:false})}
          titlemodal={`Servis Limit Değiştirme | ${this.state.currentService === null ? "" :this.state.currentService.SERVICENAME + " - " + this.state.currentService.BUSPLATE }`}
          onClick={()=> this.updateLimits}>
          <form className="address-form" name="vehicle-form">
          <div className="form-row address-row">
            <div className="col-md-6 mb-6">
              <label htmlFor={`maxpassengers_${editGuid}`}>Servis Yolcu Limiti : </label>
              <input type="number" className="form-control" id={`maxpassengers_${editGuid}`}  ref={(ref)=>{ this.maxpassengers = ref;}} placeholder="Servis Kapasitesi zorunlu" defaultValue={this.state.currentService === null ? "" : this.state.currentService.MAXPASSENGERS}  required  />
            </div>
          </div>
          <div className="form-row address-row">
            <div className="col-md-6 mb-6">
              <label htmlFor={`maxcapacity_${editGuid}`}>Servis Yolcu Kapasitesi : </label>
              <input type="number" className="form-control" id={`maxcapacity_${editGuid}`}  ref={(ref)=>{ this.maxcapacity = ref;}} placeholder="Servis Kapasitesi zorunlu" defaultValue={this.state.currentService === null ? "" : this.state.currentService.MAXCAPACITY}  required  />
            </div>
          </div>

          </form>          
        <LoadingLayer showLoading={this.props.showLoadingPopup} />
        <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />

      </PopupModal>            


      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    services: state.passenger.services,
    serviceRow: state.passenger.serviceRow,
    buses: state.definitions.buses,
    drivers: state.definitions.drivers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setBuses: (buses) => dispatch({ type: DefinitionsActionTypes.SET_BUSES, buses: buses}),
    setDrivers: (drivers) => dispatch({ type: DefinitionsActionTypes.SET_DRIVERS, drivers: drivers}),
    setServices:  (services) => dispatch({ type: PassengerActionTypes.SET_SERVICES, services: services}),
    setCurrentService: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE, payload: payload})
    
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ServiceLimits));